
import React from 'react';
import appleDownload from '../assets/apple-download-badge.svg'
import trubblLogo from '../assets/logo-300.png'
import googleDownload from '../assets/google-play-badge.png'
import Template, { Content } from '../components/Template';

export default function Download() {
  return (
    <Template className='d-flex justify-content-center align-items-center' subtitle="Download">
      <Content style={{ textAlign: 'center' }}>
          <img src={trubblLogo} className="img-fluid mb-3" alt="powered" />
          <p className='mb-3'>
            Trubbl is the universal, community-driven app that helps users
            stay updated on issues relevant to the places you frequent
            and contribute reports to help others be aware of problems.
          </p>
          <p className='mb-3'>
            Download the app and join the community.
          </p>
          <img src={appleDownload} width={150} className="img-fluid" alt="powered" />
          <img src={googleDownload} width={190} className="img-fluid" alt="powered" />
      </Content>
    </Template>
  )
}
