
// Contact.js 
import { React, useState, useContext, useEffect } from 'react';
import { FloatingLabel, Form, Button } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
import { get, post } from '../rest'
import { GlobalContext } from '../App'
import Template, { Content } from '../components/Template'
import ReportInfo from '../components/ReportInfo';
import ChannelHeader from '../components/ChannelHeader';

export default function Success() {
  const global = useContext(GlobalContext)
  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const { reportId } = useParams()

  /**
   * Get the report created in previous screen
   */
  useEffect(() => {
    const getReport = async () => {
      const token = global.getToken()
      const response = await get('report/' + reportId, token)
      return response.report
    }

    if (!global.report && reportId && !loading) {
      setLoading(true)
      getReport()
        .then(report => {
          global.setReport(report)
          global.setChannel(report.channel)
        })

        .catch(err => { global.setError('Error loading report information') })
        .finally(() => setLoading(false))
    }
  }, [global, loading, reportId])

  /**
   * Attach the email specified to the anonymous user
   * 
   * @param {*} e 
   */
  const attachEmail = (e) => {
    e.preventDefault()
    post('register/anonymous/email', { email }, global.getToken())
      .then((response) => {
        global.setToken(response.accessToken)
        navigate('/download')
      })
      .catch((err) => { global.setError('Error submitting email address') })
  }

  return (
    <Template subtitle="Success">
      <ChannelHeader channel={global.channel} />
      <Content>
        <ReportInfo report={global.report} />
        <hr />
        <h4>Thanks for your report!</h4>
        <p>
          Please leave us your email address and we'll send you updates about your issue
          and information about how to join the Trubbl community.
        </p>
        <p style={{ fontSize: 11, marginBottom: 15 }}>
          We'll never share your email with anyone without your permission.
        </p>
        <FloatingLabel
          controlId="floatingInput"
          label="Email address"
          className="mb-3"
        >
          <Form.Control
            type="email"
            placeholder="name@example.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </FloatingLabel>
        <div className="d-flex justify-content-center">
          <Button
            className='submit-button mb-4'
            style={{ marginRight: 10 }}
            variant="primary"
            type="submit"
            onClick={attachEmail}>
            Submit
          </Button>
          <Button
            className='submit-button mb-4'
            variant="secondary"
            onClick={() => navigate('/download')}>
            Skip
          </Button>
        </div>
      </Content>
    </Template>
  )
}
