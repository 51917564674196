import './App.css'
import { useEffect, useState, createContext, useContext, useRef } from 'react'
import { post } from './rest.js'
import { Route, Routes } from 'react-router-dom';
import Home from './pages/Home.js'
import Success from './pages/Success.js'
import Report from './pages/Report.js'
import Download from './pages/Download.js'
import Cookies from 'universal-cookie'
import { ErrorMessage } from './components/Messages.js';

export const GlobalContext = createContext({
  setToken: (token) => { },
  getToken: () => "",
  getOrCreateToken: async () => "",
  channel: null,
  setChannel: (channel) => { },
  report: null,
  setReport: (report) => { },
  error: null,
  setError: (error) => { },
})

function App() {
  const [channel, setChannel] = useState(null)
  const [report, setReport] = useState(null)
  const [error, setError] = useState(null)
  const tokenRef = useRef(null)


  /**
   * Set the token in the cookie and ref
   * 
   * @param {*} token 
   */
  const setToken = (token) => {
    const cookies = new Cookies()
    cookies.set('token', token, { path: '/' })
    tokenRef.current = token
  }

  /**
   * Get token from ref or cookie
   * 
   * @returns 
   */
  const getToken = () => {
    if (!tokenRef.current) {
      // get token from cookie
      const cookies = new Cookies()
      const token = cookies.get('token', { path: '/' })
      tokenRef.current = token
    }

    return tokenRef.current
  }

  /**
   * Get a token from the cookie or by creating
   * an anonumous user
   * 
   * @returns 
   */
  const getOrCreateToken = async () => {
    let token = getToken()
    if (!token) {
      // create token for anonymous user
      const response = await post('register/anonymous')
      token = response.accessToken
      setToken(token)
    }

    return token
  }

  const state = {
    getToken, getOrCreateToken, setToken,
    channel, setChannel,
    report, setReport,
    error, setError,
  }

  return (
    <GlobalContext.Provider value={state} >
      <AppRouter />
    </GlobalContext.Provider>
  )
}

function AppRouter() {
  const error = useContext(GlobalContext).error
  if (error) {
    return <ErrorMessage message={error} />
  }

  return (
    <div className="App">
      <Routes>
        <Route path='/' element={< Home />}></Route>
        <Route path='/success/:reportId' element={<Success />}></Route>
        <Route path='/download' element={<Download />}></Route>
        <Route path='/:channelId' element={<Report />}></Route>
      </Routes>
    </div>
  )
}

export default App


