import { Container } from 'react-bootstrap'
import '../App.css'
import powered from '../assets/logo-powered-by.png'
import { Helmet } from 'react-helmet'

/**
 * Show template with padding for scrolling
 * 
 * @param {*} param0 
 * @returns 
 */
export default function Template({ className, title, children }) {
  return (
    <TemplateNoScroll className={className} >
      {children}
      <div style={{ height: 100 }} />
    </TemplateNoScroll>
  )
}

/**
 * Show template
 * 
 * @param {*} param0 
 * @returns 
 */
export function TemplateNoScroll({ className, children, subtitle }) {
  const title = "Trubbl Report"
    + (subtitle ? (" | " + subtitle) : "")

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Container className={className}>
        {children}
        <PoweredLogo />
      </Container>
    </>
  )
}

export function Content({ children, style }) {
  return (
    <div className="content" style={style}>{children}</div>
  )
}

/**
 * Signup link text
 */
export function PoweredLogo() {
  return (
    <div className="text-center fixed-bottom pb-3 pt-2" style={{ backgroundColor: 'white' }}>
      <img src={powered} width='20%' className="img-fluid" alt="powered" />
    </div>
  )
}