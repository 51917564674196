import { Stack } from "react-bootstrap"
import { Avatar, avatarUri, Banner, bannerUri } from "./Avatars"

/**
 * Display the channel header
 */
export default function ChannelHeader({ channel }) {
  if (!channel) return null
  const hasBanner = bannerUri(channel)
  const hasAvatar = avatarUri(channel)

  if (!hasBanner) {
    if (hasAvatar) {
      return (
        <div className="channel-header">
          <Stack direction="horizontal">
            <Avatar channel={channel} size={60} />
            <p style={{ marginLeft: 10 }}>
              {channel.name}
            </p>
          </Stack>
        </div>
      )
    } else {
      return (
        <div className="channel-header">
          <p style={{ textAlign: 'center' }}>{channel.name}</p>
        </div>
      )
    }
  } else {
    return (
      <div className="channel-banner-header">
        <div style={{ position: 'relative' }}>
          <Banner channel={channel} />
          <div style={{ position: "absolute", left: "16px", bottom: "-30px" }}>
            <Avatar channel={channel} size={60} />
          </div>
        </div>
        <div style={{ marginLeft: '80px' }}>
          <p>{channel.name}</p>
        </div>
      </div>
    )
  }
}

