import { mediaUri } from "../rest"


/**
 * Display the channel avatar
 * 
 * @param {*} param0 
 * @returns 
 */
export function Avatar({ channel, size }) {
  const thumbnail = size <= 75
  return <img
    src={avatarUri(channel, thumbnail)}
    alt={channel.name}
    style={{
      height: size,
      width: size,
      borderRadius: size / 2,
    }}
  />
}

/**
 * Display the organization banner
 * 
 * @param {*} param0 
 * @returns 
 */
export function Banner({ channel }) {
  return <img
    src={bannerUri(channel, true)}
    alt={channel.name}
    style={{
      width: "100%",
      objectFit: "cover",
    }}
  />
}

/**
 * Get the channel avatar uri. Fallback to the organization avatar
 * 
 * @param {*} channel 
 * @returns 
 */
export function avatarUri(channel, thumbnail=false) {
  return channel.avatar
    ? mediaUri(channel.avatar, thumbnail)
    : (channel.organization && channel.organization.avatar
      ? mediaUri(channel.organization.avatar, thumbnail)
      : null
    )
}

/**
 * Get the channel avatar uri. Fallback to the organization avatar
 * 
 * @param {*} channel 
 * @returns 
 */
export function bannerUri(channel, thumbnail=false) {
  const organization = channel.organization
  return organization && organization.banner
    ? mediaUri(organization.banner, thumbnail)
    : null
}
